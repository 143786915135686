import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";

const Kirim = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      > 
      <Helmet>
        <title>Kirim Tulisan - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Kirimkan tulisan kamu untuk dimuat di blog Ethical Hacker Indonesia. Setelah itu kamu akan terdaftar sebagai kontributor." />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/kirim/" />
        <meta property="og:title" content="Kirim Tulisan" />
        <meta property="og:description" content="Kirimkan tulisan kamu untuk dimuat di blog Ethical Hacker Indonesia. Setelah itu kamu akan terdaftar sebagai kontributor." />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Kirim Tulisan"  />
        <meta name="twitter:description" content="Kirimkan tulisan kamu untuk dimuat di blog Ethical Hacker Indonesia. Setelah itu kamu akan terdaftar sebagai kontributor." />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>
        <div className="inner-banner pt-29 pb-6">  
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Kirim Tulisan
                  </h2>
                  <p className="gr-text-8 mb-13">
                    Kirimkan tulisan kamu untuk dimuat di blog Ethical Hacker Indonesia. Setelah itu kamu akan terdaftar sebagai kontributor.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-default-2 pt-15 pt-lg-23 pb-13 pb-lg-27">
          <Container>
            <div className="section-title text-center mb-13 mb-lg-20">
              <h2 className="gr-text-3 mb-0">Form Pengiriman Tulisan</h2>
              <p className="gr-text-8 mb-13">
                    Silakan isi <i>form</i> di bawah ini dengan data yang kami butuhkan.
              </p>
            </div>
            <Row>
              <Col xs="12">
                <div className="px-md-13 px-xl-15">
                  <form accept-charset="UTF-8" action="https://www.formbackend.com/f/49838116767864e5" method="POST" className="cta-form bg-white light-mode rounded-10 px-8 py-8">
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="name">Nama Lengkap </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="name"
                    name="name"
                    placeholder="i.e. Budi"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="email">Email </label>
                  <input
                    type="email"
                    className="form-control gr-text-11"
                    id="email"
                    name="email"
                    placeholder="i.e. budi@mail.com"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="phone">Nomor HP </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="phone"
                    name="phone"
                    placeholder="i.e. +6281234567890"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="telegram">Akun Telegram </label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="telegram"
                    name="telegram"
                    placeholder="i.e. @durov"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="judul">Judul Tulisan</label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="judul"
                    name="judul"
                    placeholder="i.e. Cache poisoning via X-Forwarded-Host in target.com/blog"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="bahasa">Format Bahasa</label>
                  <input
                    type="text"
                    className="form-control gr-text-11"
                    id="bahasa"
                    name="bahasa"
                    placeholder="i.e. English, Indonesia"
                    required
                  />
                </div>
                <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                  <label htmlFor="link">Link tulisan</label>
                  <textarea
                    id="link"
                    name="link"
                    className="form-control gr-text-11 border-gray-3 gr-textarea-height"
                    placeholder="Paste link tulisan kamu disini"
                    required
                    ></textarea>
                </div>
                <Button type="submit" className="gr-hover-y rounded-8 w-100">
                  Kirim
                </Button>
                </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        
      </PageWrapper>
    </>
  );
};
export default Kirim;
